import { isNumber } from "lodash";

import { DateFns, ConstantFormat } from "../../../../../../../utils/DateFns";

const formatDate = (
	date: string | number | Date,
	isTitle?: boolean,
): string => {
	const formatTime = ConstantFormat[isTitle ? "MDY_UK" : "MDYHM_UK"];
	const dateFns = new DateFns();

	const retval = dateFns.formatTime(date, formatTime);
	return retval;
};

const formatDistance = (distance: number): string => {
	const value = isNumber(distance) ? distance : 0;

	const retval = (value / 1000).toFixed(3);
	return retval;
};

const formatMoney = (money: number): string => {
	const value = isNumber(money) ? money : 0;

	const retval = value.toFixed(2);
	return retval;
};

const formatTimeToMinutes = (milliseconds: number): number => {
	if (!isNumber(milliseconds)) {
		return 0;
	}

	const seconds = Math.abs(milliseconds) / 1000;
	const minutes = Math.floor(seconds / 60);
	return minutes;
};

export { formatDate, formatDistance, formatMoney, formatTimeToMinutes };
