import makeTable from "./makeTable";

const cashlessOrders = makeTable({
	allIds: [
		"card",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"licensePlate",
		"model",
		"color",
		"distanceKm",
		"executionTimeMin",
		"idleTimeMin",
		"amountUah",
		"status",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.cashlessOrders.columns.${id}`,
	defaultIds: [
		"card",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"licensePlate",
		"model",
		"color",
		"distanceKm",
		"executionTimeMin",
		"idleTimeMin",
		"amountUah",
		"status",
	],
	initialSortMethod: {
		column: "created",
		type: "asc",
	},
	initialWidths: {
		card: 100,
		passenger: 120,
		from: 120,
		to: 120,
		note: 120,
		orderNote: 120,
		created: 120,
		when: 120,
		completed: 120,
		callSign: 120,
		licensePlate: 120,
		model: 120,
		color: 100,
		distanceKm: 120,
		executionTimeMin: 120,
		idleTimeMin: 120,
		amountUah: 120,
		status: 120,
	},
	initialResizable: {
		card: true,
		passenger: true,
		from: true,
		to: true,
		note: true,
		orderNote: true,
		created: true,
		when: true,
		completed: true,
		callSign: true,
		licensePlate: true,
		model: true,
		color: true,
		distanceKm: true,
		executionTimeMin: true,
		idleTimeMin: true,
		amountUah: true,
		status: true,
	},
	initialSortable: {
		card: true,
		passenger: true,
		from: true,
		to: true,
		note: true,
		orderNote: true,
		created: true,
		when: true,
		completed: true,
		callSign: true,
		licensePlate: true,
		model: true,
		color: true,
		distanceKm: true,
		executionTimeMin: true,
		idleTimeMin: true,
		amountUah: true,
		status: true,
	},
});

export default cashlessOrders;
