import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";

import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import {
	formatDate,
	formatDistance,
	formatMoney,
	formatTimeToMinutes,
} from "../../pages/MainPage/pages/Reports/pages/AccountingReports/utils/formatForFile";
import { Base, Language } from "..";

class CashlessOrdersReport extends Base {
	static fromResponse(data: any): CashlessOrdersReport.Model {
		return data || [];
	}

	public static async getCashlessOrdersReport(
		params: CashlessOrdersReport.SearchOptions,
	): Promise<CashlessOrdersReport.GetCashlessOrdersReportResponse | null> {
		try {
			console.log(
				"[CashlessOrdersReport] getCashlessOrdersReport params",
				{
					params,
				},
			);
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getCashlessOrdersReport(params),
			);

			console.log(
				"[CashlessOrdersReport] getCashlessOrdersReport response",
				{
					response,
				},
			);
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			console.log("[CashlessOrdersReport] Error", error);

			return null;
		}
	}

	/** src\pages\PreferencesPages\components\ScreenDirectory\components\Objects\components\ImportModal */
	public static exportPDF = async (
		data: CashlessOrdersReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			// get width from table
			width: number;
			// get height from table
			height: number;
			general: boolean;
			ColumnId: any; // todo do something with type
			columnIds: any; // todo do something with type
			textTranslations: Record<string, string>;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,
			general = false,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
		} = options;

		const doc = new JSPDF({
			orientation: "landscape",
			format: [width, height],
		});
		let currentY = 10;

		data.forEach(({ taxiService, orders, dateRange, counter }) => {
			doc.setFont("Roboto-Regular", "normal");

			doc.text(
				[
					`${taxiService.company.name} ${taxiService.name}`,
					`${formatDate(dateRange.from, true)} - ${formatDate(
						dateRange.to,
						true,
					)}`,
				],
				2000,
				currentY,
			);
			currentY += 20;

			const tableRows = orders.map((order) => {
				const payload: RowInput = [];
				const columnIdSet = new Set(columnIds);

				if (general) {
					if (columnIdSet.has(ColumnId.Order)) {
						payload.push({
							content: `${order.orderNumber}` || "",
						});
					}
					if (columnIdSet.has(ColumnId.Phone)) {
						payload.push({
							content: `${order.phone}` || "",
						});
					}
				}

				const retval: RowInput = [];

				if (columnIdSet.has(ColumnId.Card)) {
					retval.push({ content: `${order.checkCard}` });
				}
				if (columnIdSet.has(ColumnId.Passenger)) {
					retval.push({ content: `${order.fullName}` });
				}
				if (columnIdSet.has(ColumnId.From)) {
					retval.push({ content: `${order.addressFrom}` });
				}
				if (columnIdSet.has(ColumnId.To)) {
					retval.push({ content: `${order.addressTo}` });
				}
				if (columnIdSet.has(ColumnId.Note)) {
					retval.push({ content: `${order.orderNotes}` });
				}
				if (columnIdSet.has(ColumnId.OrderNote)) {
					retval.push({
						content: `${order.executorNotes}`,
					});
				}
				if (columnIdSet.has(ColumnId.Created)) {
					retval.push({
						content: formatDate(order.createdAt),
					});
				}
				if (columnIdSet.has(ColumnId.When)) {
					retval.push({
						content: formatDate(order.arrivalTime),
					});
				}
				if (columnIdSet.has(ColumnId.Completed)) {
					retval.push({
						content: formatDate(order.closedAt),
					});
				}
				if (columnIdSet.has(ColumnId.CallSign)) {
					retval.push({
						content: `${order.executorCallSign}`,
					});
				}
				if (columnIdSet.has(ColumnId.LicensePlate)) {
					retval.push({
						content: `${order.registrationNumber}`,
					});
				}
				if (columnIdSet.has(ColumnId.Model)) {
					retval.push({ content: `${order.carType}` });
				}
				if (columnIdSet.has(ColumnId.Color)) {
					retval.push({ content: `${order.carColor}` });
				}
				if (columnIdSet.has(ColumnId.DistanceKm)) {
					retval.push({
						content: formatDistance(order.distance),
					});
				}
				if (columnIdSet.has(ColumnId.ExecutionTimeMin)) {
					retval.push({
						content: `${formatTimeToMinutes(order.executingTime)}`,
					});
				}
				if (columnIdSet.has(ColumnId.IdleTimeMin)) {
					retval.push({ content: order.delay });
				}
				if (columnIdSet.has(ColumnId.AmountUah)) {
					retval.push({
						content: `${formatMoney(order.price)}`,
					});
				}
				if (columnIdSet.has(ColumnId.Status)) {
					retval.push({
						content: order.status
							? `${textTranslations.completed}`
							: `${textTranslations.pending}`,
					});
				}

				return [...payload, ...retval] as RowInput;
			});

			// const tableRows = orders.map((order) => {
			// 	const payload: RowInput = [];

			// 	if (general) {
			// 		payload.push({ content: `${order.orderNumber}` || "" });
			// 		payload.push({ content: `${order.phone}` || "" });
			// 	}

			// 	return [
			// 		...payload,
			// 		{ content: `${order.checkCard}` },
			// 		{ content: `${order.fullName}` },
			// 		{ content: `${order.addressFrom}` },
			// 		{ content: `${order.addressTo}` },
			// 		{ content: `${order.orderNotes}` },
			// 		{ content: `${order.executorNotes}` },
			// 		{ content: formatDate(order.createdAt) },
			// 		{ content: formatDate(order.arrivalTime) },
			// 		{ content: formatDate(order.closedAt) },
			// 		{ content: `${order.executorCallSign}` },
			// 		{ content: `${order.registrationNumber}` },
			// 		{ content: `${order.carType}` },
			// 		{ content: `${order.carColor}` },
			// 		{ content: formatDistance(order.distance) },
			// 		{
			// 			content: `${formatTimeToMinutes(
			// 				order.executingTime,
			// 			)}`,
			// 		},
			// 		{ content: order.delay },
			// 		{ content: `${formatMoney(order.price)}` },
			// 		{ content: `${order.status ? "Completed" : "Pending"}` },
			// 	];
			// });

			// Генерация таблицы с помощью autoTable
			autoTable(doc, {
				head: [tableColumn],
				body: tableRows,
				startY: currentY,
				styles: {
					fontSize: 12, // Размер шрифта
					cellPadding: 5, // Внутренние отступы
					lineWidth: 0.1, // Толщина линии границы
					lineColor: [0, 0, 0], // Цвет границы (черный)
					font: "Roboto-Regular",
					fontStyle: "normal",
				},
				columnStyles,
				headStyles: {
					fillColor: [41, 128, 185], // Цвет фона заголовка
					textColor: [255, 255, 255], // Цвет текста заголовка
					fontSize: 10,
				},
				margin: { top: 20 }, // Отступы сверху
				tableLineWidth: 0.1, // Толщина линии таблицы
				tableLineColor: [0, 0, 0], // Цвет линии таблицы
			});

			currentY = doc?.lastAutoTable?.finalY;

			const currency = orders?.[0]?.currency?.name || "";

			doc.setFont("Roboto-Regular", "normal");
			doc.text(
				[
					`${textTranslations.successful}: ${counter.success.count}   ${textTranslations.unsuccessful}:   ${textTranslations.totalOrders}: ${counter.orders.count}`,
					`${textTranslations.amount}, ${currency}: ${counter.success.amount}  ${textTranslations.amount}, ${currency}:   ${textTranslations.total}, ${currency}: ${counter.orders.amount}`,
					// `success count:${counter.success.count} success price:${counter.success.amount} ${currency}`,
					// `filed count:${counter.filed.count} filed price:${counter.filed.amount} ${currency}`,
					// `orders count:${counter.orders.count} orders price:${counter.orders.amount} ${currency}`,
				], // todo add sums
				30,
				currentY + 20,
			);
			currentY += 50;
		});

		// Сохранение PDF
		doc.save("data.pdf");
	};

	/** src\pages\PreferencesPages\components\ScreenDirectory\components\Objects\components\ImportModal */
	public static exportExcel = (data: CashlessOrdersReport.Model.Item[]) => {
		// Преобразование данных в формате JSON в лист Excel
		const worksheet = XLSX.utils.json_to_sheet(
			[{ test: "test" }],
			// data.map((order) => ({
			// 	"Order Number": order.orderNumber || "",
			// 	Phone: order.phone || "",
			// 	"Check Card": order.checkCard,
			// 	"Full Name": order.fullName,
			// 	"Address From": order.addressFrom,
			// 	"Address To": order.addressTo,
			// 	"Order Notes": order.orderNotes,
			// 	"Executor Notes": order.executorNotes,
			// 	"Created At": formatDate(order.createdAt),
			// 	"Arrival Time": formatDate(order.arrivalTime),
			// 	"Closed At": formatDate(order.closedAt),
			// 	"Executor Call Sign": order.executorCallSign,
			// 	"Registration Number": order.registrationNumber,
			// 	"Car Type": order.carType,
			// 	"Car Color": order.carColor,
			// 	Distance: formatDistance(order.distance),
			// 	Delay: new Date(order.delay).toLocaleString(),
			// 	"Executing Time": formatTimeToMinutes(order.executingTime),
			// 	Price: formatMoney(order.price),
			// 	Status: order.status ? "Completed" : "Pending",
			// })),
		);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

		// Генерация XLSX файла
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});

		// Сохранение файла
		const file = new Blob([excelBuffer], {
			type: "application/octet-stream",
		});

		saveAs(file, "data.xlsx");
	};

	/** src\pages\PreferencesPages\components\ScreenDirectory\components\Objects\components\ImportModal */
	public static exportZip = (data: CashlessOrdersReport.Model.Item[]) => {
		const zip = new JSZip();

		// 1. Генерация XLSX файла
		const worksheet = XLSX.utils.json_to_sheet(
			[{ test: "test" }],
			// data.map((order) => ({
			// 	"Order Number": order.orderNumber || "",
			// 	Phone: order.phone || "",
			// 	"Check Card": order.checkCard,
			// 	"Full Name": order.fullName,
			// 	"Address From": order.addressFrom,
			// 	"Address To": order.addressTo,
			// 	"Order Notes": order.orderNotes,
			// 	"Executor Notes": order.executorNotes,
			// 	"Created At": formatDate(order.createdAt),
			// 	"Arrival Time": formatDate(order.arrivalTime),
			// 	"Closed At": formatDate(order.closedAt),
			// 	"Executor Call Sign": order.executorCallSign,
			// 	"Registration Number": order.registrationNumber,
			// 	"Car Type": order.carType,
			// 	"Car Color": order.carColor,
			// 	Distance: formatDistance(order.distance),
			// 	Delay: new Date(order.delay).toLocaleString(),
			// 	"Executing Time": formatTimeToMinutes(order.executingTime),
			// 	Price: formatMoney(order.price),
			// 	Status: order.status ? "Completed" : "Pending",
			// })),
		);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});

		// Добавление XLSX файла в архив
		zip.file("data.xlsx", excelBuffer);
		//  Генерация ZIP файла
		zip.generateAsync({ type: "blob" }).then((content) => {
			// Сохранение ZIP файла
			saveAs(content, "data.zip");
		});
	};
}

declare namespace CashlessOrdersReport {
	type Model = CashlessOrdersReport.Model.Item;

	interface GetCashlessOrdersReportResponse
		extends IResponseWithItems<Model> {}

	interface SearchOptions {
		successes?: boolean;
		general?: boolean;
		taxiServiceIds?: number[];
		counterpartyIds?: number[];
		cardIds?: number[];
		dateRange: DateRange;
		lang: Language;
	}

	// interface UploadOptions {
	// 	type: "pdf" | "xlsx" | "xlsx:zip";
	// 	taxiServiceId: number;
	// 	stream: ReadableStream;
	// }

	namespace Model {
		interface AmountCount {
			count: number;
			amount: number;
		}
		interface Counter {
			success: AmountCount;
			filed: AmountCount;
			orders: AmountCount;
		}

		interface Order {
			id: number;
			orderNumber?: number;
			phone?: string;
			checkCard: string;
			fullName: string;

			addressFrom: string;
			addressTo: string;

			orderNotes: string;
			executorNotes: string;

			createdAt: string | Date;
			arrivalTime: number;
			closedAt: string | Date;

			executorCallSign: string;

			registrationNumber: string;
			carType: string;
			carColor: string;

			distance: number;

			delay: number;
			executingTime: number;

			price: number;
			currency: {
				code: string;
				name: string;
				symbol: string;
			};
			status: boolean;
		}

		interface Item {
			dateRange: {
				from: Date;
				to: Date;
			};
			taxiService: {
				id: number;
				name: string;
				company: {
					id: number;
					name: string;
				};
			};
			orders: Order[];
			counter: Counter;
		}
	}
}

export default CashlessOrdersReport;
