import * as ModelEvent from "@node-elion/syncron";
import { uniq } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { ModelId } from "../../types/ModelId";
import Base from "../Base";
import Language from "../Language";
import TaxiService from "../TaxiService";
import CarClass from "../CarClass";
import ExecutorGroup from "../ExecutorGroup";

class ExecutorApp extends Base {
	public static fromResponse(data: any): ExecutorApp.Model {
		const taxiServices = data.executorAppToTaxiServices.flatMap(
			(item) => item?.taxiService,
		);

		const taxiServiceIds = uniq<number>(taxiServices.map(({ id }) => id));
		const companyIds = uniq<number>(
			taxiServices.map(({ company }) => company?.id),
		);

		const carClasses = data.executorAppToCarClasses.flatMap(
			(item) => item?.carClass,
		);

		const carClassIds = uniq<number>(carClasses?.map(({ id }) => id));

		return {
			id: data.id,
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,

			name: data.name,
			active: data.active,
			isExecutorGroup: data.isExecutorGroup,
			root: data.root,
			base: data.base,

			mainSettings: data.mainSettings,
			orderSettings: data.orderSettings,
			interfaceSettings: data.interfaceSettings,
			taximeter: data.taximeter,

			taxiServices,
			taxiServiceIds,

			carClasses,
			carClassIds,

			executorGroups: data.executorGroups || [],

			companyIds,
		};
	}

	public static toRequest(
		model: ExecutorApp.New | ExecutorApp.Modified,
	): any {
		const carClassIds = model.carClassIds?.length
			? model.carClassIds
			: undefined;

		return {
			name: model.name,
			active: model.active,
			isExecutorGroup: model.isExecutorGroup,

			mainSettings: model.mainSettings,
			orderSettings: model.orderSettings,
			taximeter: model.taximeter,
			interfaceSettings: model.interfaceSettings,
			carClassIds,
			taxiServiceIds: model.taxiServiceIds,
		};
	}

	public static async store(object: ExecutorApp.New, force?: boolean) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executorApp.create(
						ExecutorApp.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);

			console.log("ExecutorApp store", { object, res });
			return res;
		} catch (err: any) {
			if (err.message.includes("force")) return false;
			return true;
		}
	}

	public static async copy(id: number) {
		try {
			await this.request((prpc) => prpc.theirsModel.executorApp.copy(id));
		} catch (err: any) {
			throw new Error("Error in copy method:", err);
		}
	}

	public static async update(object: ExecutorApp.Modified, force?: boolean) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executorApp.update(
						object.id,
						ExecutorApp.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);
			if (res?.id) return this.fromResponse(res);

			console.log("ExecutorApp update", { res, object });
			return res;
		} catch (err: any) {
			if (err.message.includes("force")) return false;
			return true;
		}
	}

	public static async destroy(ids: number[] | number) {
		this.request((prpc) => prpc.theirsModel.executorApp.delete(ids));
	}

	public static async subscribe(
		params: ExecutorApp.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<ExecutorApp.Model>,
	): Promise<Subscription<ExecutorApp.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("ExecutorApp subscribe", state);
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.executorApp.subscribe({
					params,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "ExecutorApp.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorApp.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<ExecutorApp.SubscribeOptions>;
	}
}

export enum OrderPointsDisplay {
	ONLY_FIRST_POINT = "only_first_point_of_the_order",
	FIRST_AND_LAST_POINT = "first_and_last_point_of_the_order",
	ALL_POINTS = "all_points_of_the_order",
}

export enum DistanceCalculationMethod {
	/** select - `По прямой` */
	STRAIGHT_LINE = "straight_line",
	/** select - `OSM` */
	OSM = "osm",
}

export enum CarArriveTimeMethodEnum {
	DYNAMIC = "dynamic",
	STATIC = "static",
}

export enum MenuTypeOrderProcessingFunctions {
	/** Додати адресу */
	ADD_ADDRESS = "addAddress",
	/** Запізнення */
	DELAY = "delay",
	/** Зняття з замовлення */
	CANCEL_ORDER = "cancelOrder",
	/** Відмова клієнта */
	CLIENT_REFUSAL = "clientRefusal",
	/** Не виходять */
	STAY_OUTSIDE = "stayOutside",
	/** Стар таксиметре */
	STAR_TAXIMETER = "starTaximeter",
	/** Вести время простоя */
	ENTER_TIME_DOWNTIME = "enterTimeDowntime",
	/** Связь с диспетчером */
	CALL_TO_DISPATCHER = "callToDispatcher",
}

declare namespace ExecutorApp {
	interface Model extends NonEditableProperties {
		name: Record<Language, string>;
		active: boolean;
		isExecutorGroup: boolean;
		/** not use */
		default?: boolean;
		readonly root?: boolean;
		readonly base?: boolean;

		interfaceSettings: ExecutorApp.Model.InterfaceSettings;
		mainSettings: ExecutorApp.Model.MainSettings;
		orderSettings: ExecutorApp.Model.OrderSettings;
		taximeter: ExecutorApp.Model.TaximeterSettings;

		/** only to front */
		carClasses: CarClass.Model[];
		/** if `undefined` no changed,  if `[]` removed all data or make update */
		carClassIds: number[];

		/** only to front */
		taxiServices: TaxiService.Model[];
		/** if `undefined` no changed , if `[]` removed all data or make update */
		taxiServiceIds: number[];

		executorGroups: ExecutorGroup.Model[];

		/** only to front */
		companyIds: number[];
	}

	type New = Omit<
		Model,
		| NonEditablePropertyNames
		| "taxiServices"
		| "carClasses"
		| "companyIds"
		| "executorGroups"
	>;
	type Modified = Partial<New> & { id: ModelId };

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<
			Pick<ExecutorApp.Model, "name" | "active" | "default" | "createdAt">
		> {
		name?: string;
		active?: boolean;
		isExecutorGroup?: boolean;
		default?: boolean;

		taxiServiceIds?: number[];
		carClassIds?: number[];
	}

	namespace Model {
		interface MainSettings {
			carClasses: {
				/** `toggle` - Car class */
				active: boolean;
			};
			/** `input` - number (ms) - Interval for GPS coordinates transmission from the driver */
			gpsTransmissionInterval: number;

			/**
			 * `input` - number (m) - Radius for displaying orders in the free filter by default for the driver
			 * @deprecated
			 * */
			defaultRadiusForFreeFormOrders: number;
			/** `input` - number (m) - Interval for transmitting distances from the driver to the pickup address */
			distanceTransmissionInterval: number;
			/** `input` - number (km/h) - Minimum speed for requesting a new distance from the driver to the pickup address */
			minimumSpeedToRequestNewDistance: number;
			/** `select` - Method for calculating the distance from the driver to the pickup address */
			distanceCalculationMethod: DistanceCalculationMethod;
		}

		interface OrderSettingsGeneral {
			/** `toggle` - Show order details to the driver always */
			showOrderDetailsAlways: boolean;
			/** `toggle` - Show order details to the driver after changing the order status to 'Accepted' */
			showOrderDetailsAfterChangeToInProcess: boolean;
			/** `toggle` - Show order details to the driver after changing the order status to 'On site' */
			showOrderDetailsAfterChangeToOnSite: boolean;
			/** `toggle` - Show order details to the driver after changing the order status to 'Met' */
			showOrderDetailsAfterChangeToMet: boolean;
		}

		interface Settings {
			/** `toggle` - Pre-order time */
			orderTime: boolean;
			/** `toggle` - Order cost (Until allowed to use `splitOrderCost`) */
			orderCost: boolean;

			/** `radio button` - `true` Separate order cost (cash + non-cash), `false` Total order cost */
			splitOrderCost: boolean;

			/** `toggle` - Distance to service */
			distanceBeforeServing: boolean;
			/** `toggle` - Time before service */
			timeBeforeServing: boolean;
			/** `toggle` - Service time */
			servingTime: boolean;

			/** `toggle` - Markup coefficient */
			markupCoefficient: boolean;
			/** `toggle` - Stock exchange indicator `todo disable` */
			stockExchangeFlag: boolean;
			/** `toggle` - Transport class */
			transportClass: boolean;
			/** `toggle` - Order notes */
			orderNotes: boolean;

			/** `toggle` - Payment method */
			paymentMethod: boolean;
			/** `toggle` - Average cost per km */
			averageCostPerKm: boolean;
			/** `toggle` - Client rating */
			clientRating: boolean;
			/** `toggle` - Services */
			services: boolean;

			/** `toggle` - Route length */
			routeLength: boolean;
			/** `toggle` - Number of client trips */
			clientTrips: boolean;
			/** `toggle` - Sectors */
			sector: boolean;
			/** `toggle` - Client name */
			clientName: boolean;

			/** `toggle` - Order points */
			orderPoints: boolean;
			/** `select` - Order points */
			orderPointsSelect: OrderPointsDisplay;
		}

		interface OrderSettingsFreeForm
			extends Omit<
				Settings,
				"splitOrderCost" | "timeBeforeServing" | "servingTime"
			> {}
		interface OrderSettingsMandatoryOrder
			extends Omit<Settings, "timeBeforeServing" | "servingTime"> {}
		interface OrderSettingsMyOrders
			extends Omit<
				Settings,
				"distanceBeforeServing" | "averageCostPerKm" | "routeLength"
			> {}
		interface OrderSettingsInProgress
			extends Pick<
				Settings,
				| "orderCost"
				| "splitOrderCost"
				| "timeBeforeServing"
				| "servingTime"
				| "paymentMethod"
				| "clientRating"
				| "services"
				| "markupCoefficient"
				| "clientTrips"
				| "sector"
				| "clientName"
				| "orderNotes"
				| "transportClass"
				| "orderPoints"
				| "orderPointsSelect"
				| "stockExchangeFlag"
				| "orderTime"
			> {}

		interface OrderSettings {
			general: OrderSettingsGeneral;
			freeForm: OrderSettingsFreeForm;
			mandatoryOrder: OrderSettingsMandatoryOrder;
			myOrders: OrderSettingsMyOrders;
			inProgress: OrderSettingsInProgress;
		}

		interface TaximeterSettings extends Record<string, any> {
			/** not use */
			allowChangeTransportClass: boolean;
			/** not use */
			allowEnableDisableBarredTariff: boolean;
			/** not use */
			allowSwitchToTaximeterDuringOrderWithMultiplePoints: boolean;

			allowUseRoundup: boolean;
			allowUseTaximeter: boolean;
			allowUseTaximeterForSpecialOrders: boolean;

			registerOwnOrdersForTaximeter: boolean;
			displaySumCalculatedByTaximeter: boolean;

			startTaximeterAfterGo: boolean;
			startTaximeterAfterMeeting: boolean;
			startTaximeterAfterOrderAcceptance: boolean;

			taximeterCanBePaused: boolean;
			/** not use */
			taximeterCanBeSetToUnpausable: boolean;
			taximeterWorksForAllOrdersInNormalMode: boolean;
			taximeterWorksOnlyForOrdersFromOnePoint: boolean;
		}
		interface InterfaceSettings extends Record<string, any> {
			statusChange: InterfaceSettings.StatusChange;
			menuFunctions: InterfaceSettings.MenuFunctions;
			orderProcessingFunctions: InterfaceSettings.OrderProcessingFunctions;
			orderFilter: InterfaceSettings.OrderFilter;
			orderSorting: InterfaceSettings.OrderSorting;
			parking: InterfaceSettings.Parking;
			chain: InterfaceSettings.Chain;
			communication: InterfaceSettings.Communication;
			performerIncome: InterfaceSettings.PerformerIncome;
			ownOrderSettings: InterfaceSettings.OwnOrderSettings;
			ratingOrder: InterfaceSettings.RatingOrder;
			balance: InterfaceSettings.Balance;
			sos: InterfaceSettings.SOS;
		}

		namespace InterfaceSettings {
			interface HomeSettings {
				active: boolean;
			}

			interface ArchiveSettings {
				active: boolean;
			}

			interface MessageSettings {
				active: boolean;
				chat: { active: boolean };
				allowDispatchMessages: { active: boolean }; // Заборонити відправку повідомлень у диспетчерську +
				allowExecutorMessages: { active: boolean }; // Заборонити відправку повідомлень у диспетчерську +
				allowClientMessages: { active: boolean }; // Заборонити відправку повідомлень у диспетчерську +
			}

			interface MapSettings {
				active: boolean;
				sectors: { active: boolean }; // Відображати сектори на карті
				parking: { active: boolean }; // Відображати стоянкі на карті
				showPerformersOnMap: { active: boolean }; // Відображати виконавців на карті
				showOrdersOnMap: { active: boolean }; // Відображати замовлення на карті
			}

			interface OrderSettings {
				active: boolean;
				allowFreeBroadcast: { active: boolean }; // Дозволити вільний ефір +
				ordersTabDefault: { active: boolean }; // Відображати замовлення на час окремою вкладкою (за замовчуванням) +
			}

			interface MenuFunctions {
				home: HomeSettings; // Головна +
				archive: ArchiveSettings; // Налаштування замінити на архів +
				orders: OrderSettings; // Замовлення +
				map: MapSettings; // Карта +
				message: MessageSettings; // сообщения +
			}

			interface MenuOrderProcessingFunctions {
				active: boolean;
				type: MenuTypeOrderProcessingFunctions;
			}

			interface OrderProcessingFunctions {
				menu: MenuOrderProcessingFunctions[];
				idleWaiting: { active: boolean }; // Простой / очікування +
				callClient: { active: boolean }; // Дзвінок клієнту +
				navigation: { active: boolean }; // Навігація +
				map: { active: boolean }; // Карта +
			}

			interface OwnOrderSettings {
				active: boolean;
				byPoints: { active: boolean }; // По точкам +
				byTaximeter: { active: boolean }; // По таксометру +
				fixedAmount: { active: boolean }; // Фіксована сума +
			}

			interface StatusChange {
				statusOnlineOffline: { active: boolean }; // Статус 'Онлайн / Офлайн' +
				allowFree: { active: boolean }; // Дозволтьт нажимати вільний +
				allowBusy: { active: boolean }; // Дозволтьт нажимати зайнятий +
				allowDiner: { active: boolean }; // Дозволтьт нажимати обід +
				allowHome: { active: boolean }; // Дозволити статус "Домой" +
			}

			/**  Widgets */
			interface SOS {
				active: boolean;
				time: number;
				callNumber: number;
			}

			/**  Widgets */
			interface OrderFilter {
				active: boolean;
				orderCost: { active: boolean };
				radius: { active: boolean }; // Радіус
				costPerKm: { active: boolean }; // Вартість 1 км +
				routeLength: { active: boolean }; // Довжина маршруту +
				supplySector: { active: boolean }; // Сектор подачі +
				destinationSector: { active: boolean }; // Сектор призначення +
				distanceToSupply: { active: boolean }; // Відстань до подачі +
				ownOrdersOnly: { active: boolean }; // Тільки свої замовлення +
				orderByHome: { active: boolean }; // заказ домой +
			}
			/**  Widgets */
			interface OrderSorting {
				active: boolean;
				orderCost: { active: boolean }; // Вартість замовлення +
				costPerKm: { active: boolean }; // Вартість 1 км +
				ownOrders: { active: boolean }; // Свої замовлення +
				orderDateTime: { active: boolean }; // Дата / час замовлення +
			}
			/**  Widgets */
			interface Parking {
				active: boolean;
				park: { active: boolean }; // Стати на стоянку +
				unpark: { active: boolean }; // Знятися зі стоянки +
				giveUpQueue: { active: boolean }; // Уступити чергу на стоянці +
				changePark: { active: boolean }; // Дозволити змінити стоянку +
			}
			/**  Widgets */
			interface Chain {
				active: boolean;
			}
			/**  Widgets */
			interface Communication {
				active: boolean;
				callDispatcher: { active: boolean; phones: string[] }; // Дзвінок у диспетчерську
				requestCallback: { active: boolean }; // Запросити зворотний дзвінок
			}

			/**  Widgets */
			interface RatingOrder {
				active: boolean;
				// Рейтинг +
				showRating: { active: boolean }; // Показувати рейтинг - тут правки +
				showStars: { active: boolean }; // Показувати оцінку - тут правки +
				showPeople: { active: boolean }; // Показувати хто залишив оцінку - тут правки +
			}

			/**  Widgets */
			interface Balance {
				active: boolean;
				showIncome: { active: boolean }; // показувати транзакції
				allowAddToBalance: { active: boolean }; // дозволити додавати до балансу
				allowWithdrawFromBalance: { active: boolean }; // дозволити знімати з балансу
			}

			/**  Widgets */
			interface PerformerIncome {
				active: boolean; // Доход исполнителя
				totalOrdersProcessed: {
					active: boolean; // Всего обработано заказов
				};
				totalIncome: {
					active: boolean; // Общая сумма дохода
					orderIncome: boolean; // доход по заказам
					bonuses: boolean; // бонусы
					bonus: boolean; // премия
				};
				totalOrdersFulfilled: {
					active: boolean; // Всего выполнено заказов
					cashOrders: boolean; // наличные заказы
					cashlessOrders: boolean; // безналичные заказы
					cardPayment: boolean; // с оплатой картой
					compensated: boolean; // с компенсацией
				};
				unfulfilledOrders: {
					active: boolean; // Не выполненные заказы
					clientCancellation: boolean; // отказ клиента
					contractorFailure: boolean; // отказ исполнителя
					otherReason: boolean; // другая причина
				};
				expenses: {
					active: boolean; // Расходы
					orderCommission: boolean; // комиссия по заказам
					subscriptionFee: boolean; // абонплата
					additionalPayment: boolean; // дополнительная оплата
					penalties: boolean; // штрафы
				};
				replenishmentBalance: {
					active: boolean; // Пополнение баланса
					office: boolean; // Офис
					paymentSystems: boolean; // платежные системы
				};
			}
		}
	}
}

export default ExecutorApp;
